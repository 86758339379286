import React, { LegacyRef, useRef, useState } from 'react'
import { BLOCKS, MARKS, Block, Inline, Text } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Dolphin from '../../../../sea-site-theme/src/svg/dolphin'
import { PlayCircle, PauseCircle } from 'react-feather'
import MLine from '../../../../sea-site-theme/src/svg/m-line'
import SLine from '../../../../sea-site-theme/src/svg/s-line'
import ELine from '../../../../sea-site-theme/src/svg/e-line'
import XLine from '../../../../sea-site-theme/src/svg/x-line'
import LibertyLine from '../../../../sea-site-theme/src/svg/liberty-line'
import {
	HeroSection,
	HeroItemsContainer,
	Line,
	HeroButtons,
	HeroSeeAllLink,
	HeroSeeAll,
	HeroBookLink,
	HeroBook,
	Bold,
	Heading1,
	BannerText,
} from './styles'

interface HeroProps {
	mainActionText: string
	mainActionUrl: string
	secondActionText: string
	secondActionUrl: string
	description: {
		raw: string
	}
	videourl: {
		file?: {
			url?: string
		}
	}
	seriesName: string
	active: null | boolean
	image: null | {
		file: {
			url: string
		}
		description: string
	}
	bannerSources: {
		media: string
		src: string
		srcSet: string
		srcSetWebp: string
		srcWebp: string
	}[]
}

const heroBannerOptions = {
	renderMark: {
		[MARKS.BOLD]: function BoldText(text: React.ReactChild | React.ReactFragment | null | undefined) {
			return <Bold>{text}</Bold>
		},
	},
	renderNode: {
		[BLOCKS.HEADING_1]: function HeadOne(
			node: Block | Inline | Text,
			children: React.ReactChild | React.ReactFragment | null | undefined
		) {
			return <Heading1>{children}</Heading1>
		},
		[BLOCKS.PARAGRAPH]: function Paragraph(
			node: Block | Inline | Text,
			children: React.ReactChild | React.ReactFragment | null | undefined
		) {
			return <BannerText>{children}</BannerText>
		},
	},
}

const Hero = ({
	mainActionText,
	mainActionUrl,
	secondActionText,
	secondActionUrl,
	description,
	videourl,
	image,
	seriesName,
	bannerSources,
	active,
}: HeroProps): JSX.Element => {
	const [control, setControl] = useState(true)
	const play = useRef<HTMLVideoElement | null>(null)
	const playVidHandler = () => {
		setControl(false)
		play != null || play != undefined ? play.current?.play() : null
	}
	const pauseVidHandler = () => {
		setControl(true)
		play != null || play != undefined ? play.current?.pause() : null
	}
	return (
		<HeroSection>
			<video ref={(play as unknown) as LegacyRef<HTMLVideoElement> | undefined} width="100%" height="100%" loop>
				<source src={videourl?.file?.url} type="video/mp4"></source>
				<track kind="captions"></track>
			</video>
			<HeroItemsContainer sources={control === true ? bannerSources : []}>
				{control ? (
					<>
						{image === null ? (
							<Dolphin width="400px" height="250px" />
						) : (
							<img className="brandImage" src={image.file.url} alt={image.description} />
						)}
						<Line>
							{seriesName === 'M Line' ? (
								<MLine width="100%" height="100%" />
							) : seriesName === 'S Line' ? (
								<SLine width="100%" height="100%" />
							) : seriesName === 'X Line' ? (
								<XLine width="100%" height="100%" />
							) : seriesName === 'E Line' ? (
								<ELine width="100%" height="100%" />
							) : seriesName === 'Liberty Line' ? (
								<LibertyLine width="100%" height="100%" />
							) : (
								''
							)}
						</Line>
						{documentToReactComponents(JSON.parse(description.raw), heroBannerOptions)}
						<HeroButtons>
							{active === null || active === true ? (
								<>
									{mainActionUrl != undefined || mainActionUrl != null ? (
										<HeroSeeAllLink to={'/' + mainActionUrl}>
											{mainActionText != undefined || mainActionText != null ? (
												<HeroSeeAll>{mainActionText}</HeroSeeAll>
											) : (
												''
											)}
										</HeroSeeAllLink>
									) : (
										''
									)}
									{secondActionUrl != undefined || secondActionUrl != null ? (
										<HeroBookLink to={'/' + secondActionUrl}>
											{secondActionText != undefined || secondActionText != null ? (
												<HeroBook>{secondActionText}</HeroBook>
											) : (
												''
											)}
										</HeroBookLink>
									) : (
										''
									)}
								</>
							) : (
								<HeroSeeAllLink to={`/contact-us`}>
									<HeroSeeAll>enquire now</HeroSeeAll>
								</HeroSeeAllLink>
							)}
						</HeroButtons>
					</>
				) : (
					''
				)}
				{control ? (
					<PlayCircle onClick={playVidHandler} className="play" color="#fff" width="70" height="70" />
				) : (
					<PauseCircle onClick={pauseVidHandler} className="pause" color="#fff" width="70" height="70" />
				)}
			</HeroItemsContainer>
		</HeroSection>
	)
}

export default Hero
